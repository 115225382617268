const Baseblu = ({
  width,
  viewBox
}: {
  width: string;
  viewBox: string;
}): JSX.Element => (
  <svg
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={viewBox}
  >
    <g transform="translate(0.000000,141.000000) scale(0.100000,-0.100000)">
      <path d="M2906 1395 c-3 -8 -18 -17 -33 -20 -87 -19 -164 -69 -226 -147 -67
-84 -92 -173 -82 -291 8 -87 42 -162 97 -212 72 -66 122 -87 328 -135 297 -69
354 -96 366 -177 10 -66 -24 -126 -90 -160 -47 -24 -61 -27 -171 -27 -103 0
-127 3 -166 22 -57 26 -94 69 -110 125 -20 68 -19 68 -153 65 -120 -3 -121 -3
-124 -27 -6 -40 29 -152 63 -207 59 -92 154 -155 285 -189 97 -25 361 -18 447
13 115 41 198 113 250 221 26 51 28 65 27 166 0 132 -12 170 -76 240 -61 67
-126 97 -300 140 -289 71 -351 92 -383 125 -36 37 -45 78 -30 124 30 85 91
116 232 116 145 -1 212 -39 252 -146 l19 -49 121 -3 c142 -3 141 -3 122 92
-17 81 -43 129 -107 196 -51 53 -161 120 -198 120 -25 0 -46 12 -46 26 0 21
-305 21 -314 -1z M0 689 l0 -671 353 5 c364 5 403 9 501 51 48 21 137 109 164 162 32
64 55 172 48 227 -15 109 -78 218 -145 250 -28 13 -42 57 -19 57 17 0 67 53
93 100 17 29 20 54 20 145 0 100 -3 115 -27 161 -46 88 -133 148 -247 173 -32
7 -182 11 -396 11 l-345 0 0 -671z m665 423 c123 -42 129 -197 10 -257 -36
-18 -60 -20 -225 -20 l-185 0 -3 134 c-1 74 0 141 2 148 9 21 336 17 401 -5z
m40 -524 c44 -24 62 -47 75 -100 10 -38 9 -56 -4 -100 -8 -30 -26 -64 -38 -75
-49 -46 -85 -53 -288 -53 l-190 0 0 174 c0 105 4 176 10 178 5 2 99 1 207 -2
157 -4 204 -9 228 -22z M1666 1338 c-8 -13 -26 -57 -40 -98 -14 -41 -41 -120 -61 -175 -20
-55 -40 -113 -45 -130 -5 -16 -25 -75 -45 -130 -20 -55 -40 -113 -45 -130 -5
-16 -25 -75 -45 -130 -20 -55 -40 -113 -45 -130 -5 -16 -15 -48 -24 -70 -77
-208 -105 -299 -95 -312 9 -10 44 -13 135 -13 l124 0 19 43 c11 23 31 81 46
129 14 48 32 92 39 98 9 7 88 9 240 8 l227 -3 19 -45 c10 -25 28 -79 41 -120
33 -112 31 -110 173 -110 67 0 127 4 135 9 17 11 11 36 -44 186 -20 55 -40
114 -45 130 -5 17 -25 75 -45 130 -20 55 -40 114 -45 130 -5 17 -25 75 -45
130 -20 55 -40 114 -45 130 -5 17 -39 116 -75 220 -37 105 -71 203 -75 218
-17 56 -21 57 -176 57 -138 0 -144 -1 -158 -22z m193 -405 c58 -190 84 -271
97 -306 8 -20 14 -51 14 -67 l0 -30 -149 0 c-83 0 -152 4 -156 9 -5 10 4 42
80 276 24 72 46 145 50 163 4 21 13 32 23 32 13 0 24 -21 41 -77z M3826 1344 c-3 -8 -6 -305 -6 -658 0 -486 3 -645 12 -654 16 -16 929
-17 959 -2 17 10 19 22 19 119 0 108 0 109 -26 115 -14 4 -171 6 -349 6 -179
-1 -329 1 -335 5 -16 10 -13 310 3 324 9 7 112 12 313 13 l299 3 5 100 c3 55
1 106 -3 113 -7 9 -81 12 -312 12 -267 0 -304 2 -309 16 -10 27 -7 252 4 259
6 4 150 6 320 5 170 0 320 2 335 6 l26 6 -3 112 -3 111 -471 3 c-424 2 -472 1
-478 -14z M5536 1344 c-3 -9 -6 -305 -6 -659 0 -553 2 -644 15 -655 22 -18 680
-8 757 13 90 24 156 65 209 132 27 33 48 66 49 73 0 7 8 25 17 39 14 21 17 49
17 137 0 104 -2 115 -30 171 -22 44 -46 72 -87 103 -60 46 -68 59 -39 69 30
11 81 76 102 131 60 158 -17 355 -165 421 -79 36 -137 40 -486 41 -307 0 -348
-2 -353 -16z m686 -237 c53 -24 68 -51 68 -123 0 -71 -20 -105 -78 -131 -47
-21 -367 -32 -401 -14 -20 11 -21 20 -21 146 0 97 3 135 13 139 6 3 95 4 197
3 147 -3 193 -7 222 -20z m20 -521 c56 -27 78 -70 78 -148 0 -56 -4 -71 -29
-105 -48 -66 -83 -74 -306 -71 l-190 3 -3 160 c-1 87 0 165 3 173 4 10 45 13
207 10 174 -3 208 -6 240 -22z M6837 1353 c-9 -8 -9 -1296 -1 -1317 5 -14 54 -16 446 -16 284 0 446
4 459 10 17 10 19 22 19 116 0 104 0 105 -27 115 -16 6 -144 9 -321 9 -236 -1
-297 1 -303 12 -5 7 -9 243 -9 523 0 281 0 520 0 533 0 22 -2 22 -128 22 -71
0 -132 -3 -135 -7z M7947 1353 c-4 -3 -7 -215 -7 -470 0 -492 4 -541 51 -648 26 -59 100
-139 162 -174 92 -52 153 -63 327 -58 85 2 171 8 190 14 67 19 148 65 188 107
43 43 102 151 102 185 0 31 19 73 30 66 7 -4 10 161 10 490 l0 496 -142 -3
-143 -3 -6 -470 c-5 -460 -7 -495 -39 -556 -35 -65 -106 -99 -209 -99 -84 0
-150 26 -187 75 -50 66 -54 103 -54 582 0 243 -3 448 -6 457 -5 13 -27 16
-133 16 -70 0 -131 -3 -134 -7z"
      />
    </g>
  </svg>
);

export default Baseblu;
