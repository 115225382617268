import {
  lazy,
  Suspense,
} from 'react';

import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import General from './pages/dashboard/General';
import Modes from './pages/dashboard/Modes';
import ShopifyTools from './pages/dashboard/Shopify Tools';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ---- Auth Page -----
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// ----- Error pages ----
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// ---- Dashboard Pages----

// General
const Account = Loadable(lazy(() => General.Account));
const Finance = Loadable(lazy(() => General.Finance));
const Overview = Loadable(lazy(() => General.Overview));

// CloudStore
const ItemsBrowse = Loadable(lazy(() => Modes.ItemsBrowse));
const OrderSummaryNew = Loadable(lazy(() => Modes.OrderSummaryNew));
const OrderList = Loadable(lazy(() => Modes.OrderList));
const ModesOverview = Loadable(lazy(() => Modes.ModesOverview));
const StockUpdates = Loadable(lazy(() => Modes.StockUpdates));
const PriceUpdates = Loadable(lazy(() => Modes.PriceUpdates));

// Shopify Tools
const GTINlookup = Loadable(lazy(() => ShopifyTools.GTINlookup));
//* Entrupy
const Entrupy = Loadable(lazy(() => ShopifyTools.Entrupy));

// Other pages
const routes: RouteObject[] = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'finance',
        element: <Finance />
      },

      /** MODES */
      {
        path: 'modes',
        children: [
          {
            path: 'orders',
            children: [
              {
                path: '',
                element: <OrderList
                  partner="Modes"
                  key="modes"
                />
              },
              {
                path: ':orderId',
                element: <OrderSummaryNew
                  partner="Modes"
                  key="modes-summary"
                />
              }
            ]
          },
          {
            path: 'items',
            children: [
              {
                path: '',
                element: <ItemsBrowse
                  partner="Modes"
                  key="modes"
                />
              },
              {
                path: 'stockUpdates',
                element: <StockUpdates
                  partner="Modes"
                  key="modes"
                />
              },
              {
                path: 'priceUpdates',
                element: <PriceUpdates
                  partner="Modes"
                  key="modes"
                />
              }
            ]
          },
          {
            path: 'overview',
            children: [
              {
                path: '',
                element: <ModesOverview
                  partner="Modes"
                  key="modes"
                />
              }
            ]
          }
        ]
      },

      /** SHOPIFY TOOLS */
      {
        path: 'certificates',
        children: [
          {
            path: 'entrupy',
            element: <Entrupy />
          },
        ]
      },
      {
        path: 'gtin',
        children: [
          {
            path: 'lookup',
            element: <GTINlookup />
          },
        ]
      },
    ]
  },
  {
    path: '*',
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Navigate to="/dashboard/modes/overview" />
          </AuthGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
