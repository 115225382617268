import {
  lazy,
  Suspense,
} from 'react';

import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import Modes from './pages/dashboard/Modes';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ---- Auth Page -----
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// ----- Error pages ----
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// CloudStore
const OrderSummaryNew = Loadable(lazy(() => Modes.OrderSummaryNew));
const OrderList = Loadable(lazy(() => Modes.OrderList));

// Other pages
const routesModes: RouteObject[] = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      /** MODES */
      {
        path: 'modes',
        children: [
          {
            path: 'orders',
            children: [
              {
                path: '',
                element: <OrderList
                  partner="Modes"
                  key="modes"
                />
              },
              {
                path: ':orderId',
                element: <OrderSummaryNew
                  partner="Modes"
                  key="modes"
                />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Navigate to="/dashboard/modes/orders" />
          </AuthGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routesModes;
