import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import CloudstoreAPI from '../API/CloudStore';
import type { ItemsGroups } from '../types/items';

interface SettingsState {
  itemsgroups: ItemsGroups[];
}
const initialSettings = [];

const initialState: SettingsState = {
  itemsgroups: initialSettings,

};
const slice = createSlice({
  name: 'item_groups',
  initialState,
  reducers: {
    GetGroups(state: SettingsState, action: PayloadAction<ItemsGroups[]>): void {
      state.itemsgroups = action.payload;
    },
  }
});

export const { reducer } = slice;

export const GetGroups = (partner): AppThunk => async (dispatch): Promise<void> => {
  const data = await CloudstoreAPI.getItemGroups(partner);

  dispatch(slice.actions.GetGroups(data));
};

export default slice;
