import type {
  Discount,
  DiscountUnit,
  Item,
} from 'src/types/items';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import CloudStore from '../API/CloudStore';
import type { AppThunk } from '../store';
import store from '../store';

interface DiscountsState {
  cloudstoreDiscounts: Discount[];
  loading: boolean;
}

const initialState: DiscountsState = {
  cloudstoreDiscounts: [],
  loading: true
};

const slice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    GetDiscounts(
      state?: DiscountsState,
      action?: PayloadAction<Discount[]>
    ): void {
      state.cloudstoreDiscounts = action.payload;
    },
    SetDiscounts(
      state: DiscountsState,
      action: PayloadAction<Discount[]>
    ): void {
      state.cloudstoreDiscounts = action.payload;
    },
    setLoading(state: DiscountsState, action: PayloadAction<boolean>): void {
      state.loading = action.payload;
    }
  }
});

export const { reducer } = slice;

export const SetDiscount = (id, object): AppThunk => async (dispatch, state): Promise<void> => {
  let newdiscounts = state().discounts.cloudstoreDiscounts.slice();
  // eslint-disable-next-line no-underscore-dangle
  newdiscounts = newdiscounts.map((e) => (e._id === id ? object : e));
  dispatch(slice.actions.SetDiscounts(newdiscounts));
};

export const GetDiscounts = (partner): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setLoading(true));
  const data = await CloudStore.Discounts.GetList(partner);
  dispatch(slice.actions.setLoading(false));

  dispatch(slice.actions.GetDiscounts(data));
};

export const SearchDiscount = (cloudstoreitem: Item): DiscountUnit => {
  if (!cloudstoreitem) return { rate: 0, name: 'Not found' };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { brand, sex, product_group, season } = cloudstoreitem;

  const discounts = store.getState().discounts.cloudstoreDiscounts;

  const discount = discounts.find(
    (elm) => elm.brand === brand
      && elm.season === season
      && elm.product_groups.find((e) => e === product_group)
      && elm.genders.find((e) => e === sex)
  );
  if (!discount) return { rate: 0, name: 'Not found' };
  return {
    name: discount.name,
    rate: discount.rate
  };
};

export default slice;
