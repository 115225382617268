import axios from 'src/lib/axios';
import {
  Discount,
  FilterMenu,
  GetItemsResult,
  ItemsGroups,
} from 'src/types/items';

class API {
  getItems(data): Promise<GetItemsResult> {
    return axios
      .post('cloudstore/items/browse', data)
      .then((response) => response.data);
  }

  SearchItemsCloudStore(data): Promise<GetItemsResult> {
    return axios
      .post('cloudstore/items/cloudstore/search', data)
      .then((response) => response.data);
  }

  getFilterMenu(partner): Promise<FilterMenu> {
    return axios.get('cloudstore/items/settings/filters', { params: { partner } }).then((response) => response.data);
  }

  getItemGroups(partner): Promise<ItemsGroups[]> {
    return axios.get('cloudstore/items/settings/groups', { params: { partner } }).then((response) => response.data);
  }

  Discounts = {
    GetList(partner): Promise<Discount[]> {
      return axios
        .get('cloudstore/items/discounts/list', { params: { partner } })
        .then((response) => response.data);
    },
    BulkCreate(discounts, autodeactivate, partner): Promise<any> {
      return axios
        .post('cloudstore/items/discounts/bulkcreate', { discounts, autodeactivate, partner })
        .then((response) => response.data);
    },
    Update(_id, discount, partner): Promise<any> {
      return axios
        .post('cloudstore/items/discounts/update', { _id, discount, partner })
        .then((response) => response.data);
    }
  };
}

export default new API();
