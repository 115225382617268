import Modes from 'src/API/Modes';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppThunk } from '../store';
import type SkuList from '../types/skulist';

interface SKUlistState {
  variantsonshopify: SkuList[];
}

const initialState: SKUlistState = {
  variantsonshopify: []
};

const slice = createSlice({
  name: 'sku_list',
  initialState,
  reducers: {
    GetList(state: SKUlistState, action: PayloadAction<SkuList[]>): void {
      state.variantsonshopify = action.payload;
    }
  }
});

export const { reducer } = slice;

export const GetList = (): AppThunk => async (dispatch): Promise<void> => {
  const data = await Modes.getSKUList();
  dispatch(slice.actions.GetList(data));
};

export default slice;
