import type { FC } from 'react';
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import ChartPieIcon from 'src/icons/ChartPie';
import FolderOpenIcon from 'src/icons/FolderOpen';
import ShoppingCartIcon from 'src/icons/ShoppingCart';

import type { Theme } from '@material-ui/core';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import QrCodeScanner from '@material-ui/icons/QrCodeScanner';

import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

// import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import UserIcon from '../../icons/User';
// import ShoppingBagIcon from '../../icons/ShoppingBag';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  // {
  //   title: 'General',
  //   items: [
  //     {
  //       title: 'Overview',
  //       path: '/dashboard/items#',
  //       icon: <ChartSquareBarIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Finance',
  //       path: '/dashboard/items#',
  //       icon: <ShoppingBagIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Account',
  //       path: '/dashboard/items#',
  //       icon: <UserIcon fontSize="small" />
  //     }
  //   ]
  // },
  {
    title: 'Shopify Tools',
    items: [
      {
        title: 'Certificates',
        icon: <CardMembershipIcon fontSize="small" />,
        children: [
          {
            title: 'Entrupy',
            path: '/dashboard/certificates/entrupy',
          },
        ]
      },
      {
        title: 'GTIN Lookup',
        icon: <QrCodeScanner fontSize="small" />,
        path: '/dashboard/gtin/lookup',
      },
    ]
  },
  {
    title: 'Modes',
    items: [
      {
        title: 'Overview',
        icon: <ChartPieIcon fontSize="small" />,
        path: '/dashboard/modes/overview'
      },
      {
        title: 'Items',
        icon: <ShoppingCartIcon fontSize="small" />,
        path: '/dashboard/modes/items',
      },
      {
        title: 'Orders',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/dashboard/modes/orders'
      }
    ]
  }
];

const modesUserSections = [];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const isModesUser = user.modes_user;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {user.email}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {(isModesUser ? modesUserSections : sections).map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
