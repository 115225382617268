import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo2: FC<LogoProps> = (props) => (
  <LogoRoot
    version="1.1"
    viewBox="0 0 193032 49834"
    height="50"
    width="150"
    {...props}
  >
    <title>COSETTE ADMIN 2.0</title>
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2555950829744">
        <path
          fill="#F8CACA"
          d="M43098 41600c-10783,0 -9723,-24636 -7723,-32426 985,-3838 3143,-8212 7723,-8212 4254,0 6422,3863 7418,7234 2130,7214 2407,21927 -1037,28770 -1080,2147 -2362,3691 -4712,4377 -340,100 -1311,257 -1669,257zm-14116 -21709c0,4374 -29,5591 1176,9946 1060,3831 3415,7929 6461,10328 7286,5742 14976,459 18320,-7364 2653,-6206 3216,-14965 671,-21359 -205,-517 -378,-995 -595,-1543 -569,-1437 -1380,-2805 -2248,-4061 -285,-412 -606,-853 -927,-1212 -2010,-2245 -4778,-4626 -8207,-4626 -2944,0 -4926,676 -7025,2386 -3291,2680 -5139,6191 -6438,10245 -585,1828 -1188,4906 -1188,7260z"
        />
        <path
          fill="#F8CACA"
          d="M84592 1925l3957 0 0 38712 -3957 0c0,767 -106,1070 321,1070l25773 0c246,0 321,-75 321,-321l0 -11657 -856 0c0,7582 -1529,10908 -9732,10908l-7165 0 0 -19249c6337,0 8449,-305 8449,7058l855 0 0 -15399 -855 0c0,1956 -116,4219 -1069,5562 -1621,2284 -4417,1817 -7380,1817l0 -18501 7807 0c1200,0 2500,389 3334,730 378,155 988,513 1282,749l1077 1062c1138,1295 1701,3765 1678,5595 -11,807 -46,740 864,740l-244 -4676c-177,-1297 -127,-3259 -220,-4000l-71 -1270 -24169 0 0 1070z"
        />
        <path
          fill="#F8CACA"
          d="M166617 1176c0,962 -116,749 1818,749 749,0 1497,0 2246,0 -73,130 -107,114 -107,321l0 38070c0,409 -33,39 107,321l-4064 0c0,767 -106,1070 321,1070l26094 0 0 -11978 -749 0c0,3289 -443,6518 -1907,8360 -1514,1906 -4337,2548 -7718,2548l-7379 0 0 -19249c2385,0 5651,-294 7143,1199 1420,1419 1306,3817 1306,5859l855 0 0 -15399 -855 0c0,1993 23,4106 -1174,5777 -1455,2030 -4755,1602 -7275,1602l0 -18501 7700 0c5370,0 7593,3809 7593,8876l749 0c0,-2968 -441,-6227 -426,-8665 4,-615 -98,-811 -109,-1281l-23848 0c-247,0 -321,75 -321,321z"
        />
        <path
          fill="#F8CACA"
          d="M61385 10908c0,8429 1647,11412 9829,13057 5262,1057 8245,2390 8245,8759 0,6239 -4229,10119 -10054,8449 -3761,-1078 -5915,-4864 -6711,-8582 -206,-963 -462,-2910 -453,-4038 11,-1294 193,-1283 -856,-1283l0 14758c1441,0 606,-354 916,-1545 759,-2926 4259,2079 9244,2079 3788,0 6148,-1402 7851,-4019 1704,-2618 2308,-5766 2308,-9669 0,-11020 -7290,-10297 -13837,-12578 -1572,-548 -3157,-1375 -3839,-2791 -1250,-2594 -834,-8151 1340,-10485 2442,-2625 6064,-2612 8767,-798 3257,2187 4440,6454 4865,10213 116,1019 -266,1039 886,1039l0 -12191c-936,0 -774,-47 -892,498 -1031,4757 -3382,-1781 -8626,-1781 -1904,0 -2949,83 -4578,1196 -2902,1983 -4405,5867 -4405,9712z"
        />
        <path
          fill="#F8CACA"
          d="M0 19998c0,3760 34,6188 1189,9826 1279,4024 3234,7740 6556,10340 3309,2590 7909,3506 11423,606 564,-465 2773,-2699 3611,-2699 943,0 1390,1813 1390,2887l856 0 0 -13474c0,-405 -250,-321 -856,-321 0,4260 -1467,8707 -3930,11362 -1543,1665 -3430,3075 -5909,3075 -8059,0 -9090,-13066 -9090,-18394 0,-5394 -7,-10643 1777,-15762 4327,-12412 17152,-5341 17152,6672l856 0 0 -12405 -856 0c0,2826 -1546,3112 -3448,1416 -1584,-1413 -3508,-3127 -5963,-3127 -1696,0 -2975,99 -4493,854 -4824,2396 -7503,6784 -9075,11885 -593,1923 -1190,4796 -1190,7259z"
        />
        <path
          fill="#F8CACA"
          d="M113894 12940c975,0 752,135 749,-2139 -3,-1965 278,-4916 1218,-6481 1416,-2355 4198,-2395 7872,-2395 -73,130 -107,114 -107,321l0 38070c0,409 -33,39 107,321l-3957 0 0 1070 11550 0c426,0 321,-303 321,-1070l-3957 0 0 -38712c3520,0 6535,-191 8058,2957 841,1737 1032,5460 1032,8058l642 0 0 -12085 -23528 0 0 12085z"
        />
        <path
          fill="#F8CACA"
          d="M140737 1176l0 11764 748 0c0,-2939 11,-6434 1287,-8660 1428,-2492 4936,-2355 7804,-2355l0 38712 -3957 0c0,767 -106,1070 321,1070l11228 0c427,0 321,-303 321,-1070l-3956 0 0 -38712c3059,0 6414,-226 7987,2707 1008,1879 1103,5614 1103,8308 520,0 748,72 748,-321l0 -11443c0,-246 -74,-321 -321,-321l-22992 0c-247,0 -321,75 -321,321z"
        />
        <polygon
          fill="#F8CACA"
          points="84378,49834 135924,49834 135924,48872 84378,48872 "
        />
      </g>
    </g>
  </LogoRoot>
);

export default Logo2;
