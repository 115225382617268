import axios from 'src/lib/axios';
import type ImportQueue from 'src/types/importqueue';
import {
  FilterMenu,
  GetItemsResult,
  ItemsGroups,
} from 'src/types/items';
import {
  GetOrdersResult,
  ModesOrder,
} from 'src/types/order';
import SkuList from 'src/types/skulist';

class API {
  getItems(data): Promise<GetItemsResult> {
    return axios
      .post('modes/items/browse', data)
      .then(response => response.data);
  }

  SearchItemsCloudStore(data): Promise<GetItemsResult> {
    return axios
      .post('cloudstore/items/cloudstore/search', data)
      .then(response => response.data);
  }

  getFilterMenu(partner): Promise<FilterMenu> {
    return axios
      .get('modes/items/settings/filters', { params: { partner } })
      .then(response => response.data);
  }

  getItemGroups(partner): Promise<ItemsGroups[]> {
    return axios
      .get('modes/items/settings/groups', { params: { partner } })
      .then(response => response.data);
  }

  getImportQueue(email): Promise<ImportQueue[]> {
    return axios
      .get('modes/items/import', { params: { user: email } })
      .then(response => response.data);
  }

  postImportQueueAdd(data): Promise<void> {
    return axios.post('modes/items/import', data);
  }

  getSKUList(): Promise<SkuList[]> {
    return axios
      .get('modes/items/onshopify')
      .then(response => response.data);
  }

  getOrders(params): Promise<GetOrdersResult> {
    return axios
      .get('modes/orders/list', { params })
      .then(response => response.data);
  }

  printOrder(params): Promise<any> {
    return axios
      .get('modes/orders/print', { params })
      .then(response => response.data);
  }

  uploadFile(body): Promise<any> {
    const config = { headers: {
      'Content-Type': 'multipart/form-data',
    } };
    return axios
      .post('modes/orders/docupload', body, config)
      .then(response => response.data);
  }

  cancelOrder(data): Promise<any> {
    return axios
      .post('modes/orders/cancel', data)
      .then(response => response.data);
  }

  getOrder(order_number): Promise<ModesOrder> {
    return axios
      .get('modes/orders/find', { params: { order_number } })
      .then(response => response.data);
  }

  updateOrder(data): Promise<any> {
    return axios
      .post('modes/orders/update', data)
      .then(response => response.data);
  }
}

export default new API();
