import type {
  FC,
  ReactNode,
} from 'react';
import {
  createContext,
  useEffect,
  useReducer,
} from 'react';

import axios from 'src/lib/axios';

import firebase from '../lib/firebase';
import type { User } from '../types/user';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'Firebase';
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state: State, action: Action): State => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Firebase',
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

// Returns the modes_user attribute
const getIdTokenClaims = async () => {
  try {
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
    return idTokenResult.claims.modes_user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => firebase.auth().onAuthStateChanged(async (user) => {
    if (process.env.NODE_ENV === 'development') {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            id: 'local',
            avatar: '/static/icons/typescript.svg',
            email: 'dev@cosette.com.au',
            name: 'Dev',
            modes_user: false
          }
        } });
    } else if (!user) {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false,
          user: null,
        } });
    } else {
      const modesUser = await getIdTokenClaims();

      axios.post('/firebase/signin', {
        idToken: await user.getIdToken()
      }).then((response) => {
        axios.defaults.headers['firebase-IdToken'] = response.data.token;

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              name: user.displayName,
              modes_user: modesUser
            }
          } });
      }).catch((err) => {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        firebase.auth().signOut();
        return err;
      });
    }
  }), [dispatch]);

  const signInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<any> => firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const logout = async (): Promise<void> => {
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
