const Modes = ({
  width,
  viewBox
}: {
  width: string;
  viewBox: string;
}): JSX.Element => (
  <svg
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={viewBox}
  >
    <path
      d="M304.8 1.6c-52.1 0-94.5 42.4-94.5 94.5s42.4 94.5 94.5 94.5 94.5-42.4 94.5-94.5c-.1-52.1-42.4-94.5-94.5-94.5zm55.5 126.6c-10.3 17.8-25.7 31.1-43.5 37.5-17.7 6.4-35.6 5.2-50.4-3.3-30.6-17.7-38.4-61.9-17.3-98.4 15.2-26.3 41.2-41.6 66-41.6 9.7 0 19.3 2.4 27.9 7.3 14.8 8.5 24.8 23.5 28.1 42 3.3 18.7-.5 38.8-10.8 56.5zM700.9 145.9c-.2.2-19.4 20-46.5 20-23.9 0-48.1-1.1-53.4-1.4V103l85.4 3.7V79.5L601 83.2V27l59-3.1c8.2 0 17.6 2.3 27.3 6 1.9.7 3.9 1.5 6.2 2.4l13.7.1V4.1l-141.6.4V14l9.7 6.3v149.8l-9.7 6.3v9.5H709l2-40h-10.1zM465.8 4.1l-62.4.4V14l9.7 6.3v149.8l-9.7 6.3v9.5h1.4s42.4-.1 52.8-.1h.3c17.6 0 50.1-.1 72.5-16.2 24.3-17.5 30.7-56.3 30.7-74.4 0-27.4-8.8-50.5-25.5-66.8-17.2-16.6-41.4-25-69.8-24.3zm0 161.4h-26.9V28.3c21.4-8.9 46.9-8 63.3 2.8 18.9 12.3 30.3 36.3 30.5 64.1.2 22.6-6.9 41.9-19.8 54.5-11.2 10.9-29.3 16.4-47.1 15.8zM812 81.3c-4.9-.6-12.4-1-18.3-1.5-23.7-1.8-53.1-6.9-53.1-21.9 0-16.6 25.5-36.3 59.5-36.3 28.5 0 46.8 20.6 53.7 28 2.3 2.4 2.8 3 3.7 3h9.9L865.5 15S842.7 2.5 801.7 2.5c-38.9 0-61.5 9.8-75.5 32.7-10.6 17.3-10.9 44.7 8.8 57.6 12.8 8.4 21.1 10.2 51.5 14.7 4.9.7 10.4 1.5 16.6 2.5 26 4 44.7 10.6 50.7 19.3 2.2 3.2 2.9 9.6 2.1 13.3-1.3 5.7-6.1 11.5-13.6 16.2-13.7 8.6-40.6 15.3-70.1 8.3-6.2-1.5-12.3-4-19.2-7.9-3.8-2.1-7.2-5-10.3-8.4l-7.9-11.9h-10.5v33.9l.2.4c4.1 6.2 34.6 17.5 72 17.5 35.7 0 82.4-5.2 82.4-59.3.2-37-33.7-46.1-66.9-50.1zM169.5 10.9c-16-8.5-36.7-8.5-52.7 0-5.3 2.8-9.6 6.3-12.9 10.4H91V6.7H1.3V186h25.6V28.4H82v157.5h25.3V53.5c0-20.7 16.1-30.2 31.1-30.2 14.7 0 30.5 9.4 30.5 30.2v132.4h27.6s.3-96 .3-129.7c0-20.4-9.2-35.6-27.3-45.3z"
    />
  </svg>
);

export default Modes;
