import Modes from 'src/API/Modes';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppThunk } from '../store';
import type ImportQueue from '../types/importqueue';

interface ImportQueueState {
  ImportQueue: ImportQueue[];
}

const initialState: ImportQueueState = {
  ImportQueue: []
};

const slice = createSlice({
  name: 'import_queue',
  initialState,
  reducers: {
    GetQueue(
      state: ImportQueueState,
      action: PayloadAction<ImportQueue[]>
    ): void {
      state.ImportQueue = action.payload;
    },
    AddQueue(
      state: ImportQueueState,
      action: PayloadAction<ImportQueue[]>
    ): void {
      state.ImportQueue = action.payload;
    }
  }
});

export const { reducer } = slice;

export const GetQueue = (email): AppThunk => async (dispatch): Promise<void> => {
  const data = await Modes.getImportQueue(email);

  dispatch(slice.actions.GetQueue(data));
};

export const AddQueue = (sku_parent, user): AppThunk => async (dispatch, state): Promise<void> => {
  let queue = state().importqueue.ImportQueue;

  queue = [
    ...queue,
    {
      sku_parent,
      shopifyid: null,
      status: 'importing',
      error: null,
      user,
      date: ''
    }
  ];

  dispatch(slice.actions.AddQueue(queue));
};

export default slice;
