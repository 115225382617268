export default {
  ItemsBrowse: import('./Items/ItemsBrowse'),
  ItemsDiscounts: import('./Items/ItemsDiscounts'),
  StockUpdates: import('./Items/StockUpdates'),
  PriceUpdates: import('./Items/PriceUpdates'),
  OrderDetails: import('./Orders/OrderDetails'),
  OrderSummaryNew: import('../../../components/dashboard/order/OrderSummary'),
  OrderList: import('./Orders/OrderList'),
  ModesOverview: import('./Overview/ModesOverview')
};
