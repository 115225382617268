import type { FC } from 'react';

import BasebluIcon from './Baseblu';
import ModesIcon from './Modes';

const CloudStorePartnerIcon: FC<{ partner: string }> = (props) => {
  const { partner, } = props;

  switch (partner) {
    case 'Baseblu':
      return (
        <BasebluIcon
          width="70"
          viewBox="0 0 900 200"
        />
      );
    case 'Modes':
      return (
        <ModesIcon
          width="60"
          viewBox="0 0 900 200"
        />
      );
    default: return <span>{partner}</span>;
  }
};

export default CloudStorePartnerIcon;
