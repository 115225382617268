import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import ModesAPI from '../API/Modes';
import type { AppThunk } from '../store';
import type { FilterMenu } from '../types/items';

interface MenuState {
  MenuOptions: FilterMenu;
  SelectedOptions: FilterMenu;
  MenuMounted: boolean;
  partner: string;
}

const initialmenu = {
  soldout: false,
  search: [],
  price: { min: 0, max: 0 },
  color: [],
  sex: [],
  made_in: [],
  size: [],
  season: [],
  product_group: [],
  family_group: [],
  imgs: true,
  brand: [],
  onshopify: false,
  offshopify: false,
};

const initialState: [MenuState] = [{
  MenuOptions: initialmenu,
  SelectedOptions: initialmenu,
  MenuMounted: false,
  partner: null
}];

const slice = createSlice({
  name: 'items_menu',
  initialState,
  reducers: {
    MountMenu(state: [MenuState], action: PayloadAction<{ menu:FilterMenu, partner: string }>): void {
      const { partner, menu } = action.payload;
      if (!state.find((e) => e.partner === partner)) { state.push({ MenuMounted: true, MenuOptions: menu, partner, SelectedOptions: initialState[0].SelectedOptions }); } else {
        state.find((e) => e.partner === partner).MenuOptions = menu;
        state.find((e) => e.partner === partner).MenuMounted = true;
      }
    },
    ChangeOptions(state: [MenuState], action: PayloadAction<{ menu:FilterMenu, partner: string }>): void {
      const { partner, menu } = action.payload;
      state.find((e) => e.partner === partner).SelectedOptions = menu;
    }
  }
});

export const { reducer } = slice;

export const MountMenu = (partner): AppThunk => async (dispatch): Promise<void> => {
  const data = await ModesAPI.getFilterMenu(partner);

  dispatch(slice.actions.MountMenu({ menu: data, partner }));
};

export const ChangeOptions = (newoptions: FilterMenu, partner:string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.ChangeOptions({ menu: newoptions, partner }));
};

export default slice;
