const Entrupy = ({
  width,
  viewBox,
  height,
}: {
  width: string;
  viewBox: string;
  height: string;
}): JSX.Element => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    preserveAspectRatio="xMidYMid meet"
    width={width}
    fill="currentColor"
    viewBox={viewBox}
  >
    <metadata>
      Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
    <g
      transform="translate(0.000000,28.668172) scale(0.022573,-0.022573)"
      stroke="none"
    >
      <path d="M1646 1213 c-3 -3 -31 -6 -62 -7 -46 -1 -58 -4 -60 -18 -1 -10 1 -18
5 -18 4 0 15 -6 26 -13 14 -11 15 -16 5 -22 -8 -5 -11 -4 -7 2 4 6 1 13 -7 16
-16 6 -17 -34 -1 -45 6 -5 4 -8 -5 -8 -13 0 -13 -1 0 -10 10 -6 11 -10 3 -10
-7 0 -13 -5 -13 -11 0 -5 6 -7 13 -3 7 5 6 1 -3 -10 -20 -25 -22 -46 -3 -39
12 5 12 7 1 15 -11 8 -11 9 0 6 8 -2 14 -8 14 -13 1 -6 7 -15 15 -21 13 -9 13
-12 -1 -23 -9 -7 -14 -17 -11 -21 3 -5 0 -12 -6 -16 -8 -4 -9 -3 -5 4 4 7 3
12 -3 12 -5 0 -13 -4 -16 -10 -3 -5 -2 -10 4 -10 5 0 8 -8 6 -17 -1 -10 1 -25
6 -34 5 -10 3 -21 -3 -27 -7 -7 -8 -12 -4 -12 5 0 3 -7 -4 -15 -7 -8 -8 -15
-2 -15 5 0 15 6 21 14 9 11 9 15 -1 18 -8 3 -7 6 4 10 17 7 19 -7 3 -32 -5 -8
-10 -18 -11 -22 -1 -5 -2 -9 -3 -10 -12 -14 -8 -50 5 -55 13 -5 13 -7 2 -14
-7 -5 -13 -16 -13 -26 0 -10 -4 -24 -8 -30 -11 -17 5 -17 23 0 12 10 12 9 0
-7 -19 -24 -22 -49 -5 -42 8 3 12 -2 11 -12 -2 -9 -9 -18 -17 -20 -11 -3 -11
-1 1 8 12 9 12 11 1 8 -8 -2 -16 -14 -17 -26 -3 -16 4 -26 26 -37 17 -9 30
-18 30 -21 0 -2 -9 0 -19 6 -26 13 -27 13 -28 -9 -1 -10 8 -23 20 -28 19 -8
19 -10 3 -16 -10 -4 -15 -3 -11 3 3 6 2 10 -3 10 -5 0 -9 -13 -8 -30 1 -30 10
-38 20 -21 4 5 0 12 -6 14 -9 4 -10 6 -1 6 22 2 24 -17 3 -32 -19 -13 -19 -16
-6 -30 8 -8 12 -18 8 -22 -4 -5 1 -5 10 -2 13 4 16 1 13 -14 -3 -14 0 -19 9
-16 7 3 13 -1 13 -8 0 -7 -7 -11 -16 -8 -26 8 5 -13 63 -43 69 -36 76 -37 218
-32 l47 2 -3 38 c-2 24 -10 44 -22 53 -19 13 -19 13 1 12 12 -1 20 4 20 13 0
11 -11 15 -40 15 -45 0 -108 23 -99 36 3 5 -2 12 -10 15 -9 3 -13 10 -10 15 3
5 -1 9 -8 9 -9 0 -9 3 2 10 11 7 11 10 2 10 -8 0 -10 5 -6 12 5 8 1 9 -12 5
-15 -6 -13 -2 6 15 14 12 18 19 8 15 -19 -7 -19 13 0 36 10 12 9 16 -5 22 -17
6 -17 7 2 22 20 15 20 16 0 9 -11 -4 -16 -4 -12 0 4 4 -1 13 -11 21 -15 13
-15 13 5 4 12 -6 24 -11 26 -11 10 0 1 36 -11 43 -7 5 -9 13 -6 19 5 7 2 9 -8
6 -8 -4 -15 0 -15 7 0 10 5 11 18 4 25 -13 27 -12 25 21 0 17 -4 30 -8 30 -8
0 -4 27 5 36 4 4 -1 13 -11 21 -9 8 -11 12 -4 8 13 -7 13 -5 16 63 1 29 -2 41
-10 38 -10 -4 -12 21 -4 32 2 1 4 7 5 12 1 6 3 11 5 13 7 8 7 37 0 37 -15 0
-21 -18 -8 -22 8 -3 7 -6 -3 -10 -12 -5 -15 0 -14 20 1 21 6 26 21 24 18 -3
18 -3 -1 13 -11 9 -14 14 -7 10 7 -4 12 -3 11 2 -1 4 0 10 1 13 1 3 3 18 4 33
2 19 -1 26 -10 23 -14 -5 -13 11 1 34 12 20 15 39 6 44 -12 7 -89 15 -95 9z"
      />
      <path d="M270 979 c-25 -5 -52 -10 -60 -11 -8 0 -30 -11 -49 -24 -19 -13 -39
-20 -44 -17 -6 3 -7 1 -3 -5 3 -5 0 -13 -6 -15 -10 -4 -9 -8 2 -17 13 -10 12
-11 -3 -6 -16 5 -31 -15 -42 -56 -2 -5 -4 -10 -5 -13 -6 -12 -6 -31 1 -45 5
-9 1 -23 -9 -34 -13 -15 -13 -17 -2 -11 13 8 13 7 1 -8 -9 -12 -10 -17 -2 -17
7 0 9 -4 6 -10 -3 -5 -1 -10 6 -10 7 0 4 -6 -7 -14 -18 -14 -18 -15 -1 -21 14
-6 15 -10 5 -22 -17 -21 -19 -46 -4 -40 7 3 13 -1 13 -9 0 -7 -5 -10 -10 -7
-6 3 -7 1 -3 -5 3 -6 2 -13 -3 -17 -10 -5 -8 -36 3 -54 4 -5 0 -12 -6 -14 -7
-3 -8 -6 -2 -6 5 -1 19 6 30 15 17 14 17 15 1 10 -11 -3 -16 -2 -12 4 3 6 0
10 -7 10 -10 0 -10 2 1 9 11 7 11 9 0 13 -9 3 -9 6 3 11 12 4 16 1 13 -13 -2
-10 3 -21 12 -24 11 -5 13 -10 4 -20 -6 -7 -8 -16 -4 -20 4 -4 0 -4 -8 -1 -9
3 -22 -2 -30 -11 -12 -16 -12 -17 5 -11 15 6 17 5 8 -6 -9 -10 -7 -19 5 -37 9
-13 11 -20 4 -15 -10 6 -11 3 -2 -14 7 -11 17 -21 24 -21 9 0 8 -3 -1 -9 -10
-6 -5 -11 17 -21 17 -7 29 -15 26 -19 -5 -10 40 -34 93 -51 35 -11 48 -11 64
-1 11 7 17 8 14 3 -3 -6 1 -13 10 -16 8 -3 12 -2 9 4 -3 6 -2 10 3 10 24 0 20
31 -6 44 -15 9 -23 15 -18 16 6 0 1 7 -10 15 -18 14 -17 15 8 4 15 -7 25 -17
21 -23 -3 -6 -2 -7 2 -3 13 12 15 37 2 37 -6 0 -3 6 7 13 16 12 14 15 -24 32
-22 10 -43 23 -47 27 -3 5 14 0 37 -12 23 -12 44 -19 47 -17 2 3 -5 8 -17 12
-24 8 -86 65 -70 65 5 0 10 5 10 11 0 5 -5 7 -12 3 -7 -5 -8 -3 -2 7 16 26 12
37 -17 50 -16 6 -32 16 -36 22 -3 6 -1 7 5 3 7 -4 17 -4 23 0 8 4 4 11 -11 19
-19 10 -20 13 -6 18 12 5 15 3 10 -5 -5 -8 -2 -9 10 -5 10 4 15 3 11 -3 -8
-13 8 -13 22 1 5 5 25 11 42 11 17 1 34 5 37 9 3 5 -3 6 -13 2 -10 -3 -15 -3
-11 2 4 4 3 16 -3 26 -8 15 -6 18 8 13 17 -5 17 -5 0 6 -9 6 -21 9 -25 6 -5
-3 -9 0 -9 7 0 7 8 13 18 13 10 0 15 -5 12 -10 -3 -5 -1 -6 6 -2 17 11 3 26
-24 26 -13 0 -27 5 -30 10 -3 6 -12 10 -18 10 -8 0 -7 -3 2 -9 7 -5 9 -11 5
-13 -5 -3 -4 -4 2 -2 6 1 11 -4 11 -11 0 -10 -6 -11 -24 -2 -15 6 -19 13 -12
17 7 5 5 9 -6 14 -9 3 -22 3 -27 -1 -7 -4 -8 -1 -4 6 5 7 15 10 23 7 8 -3 24
1 36 9 12 8 16 15 10 15 -13 0 -12 17 5 46 10 19 8 24 -11 38 -21 16 -21 16
-1 7 25 -12 63 -7 78 10 7 8 3 7 -14 -1 -20 -10 -23 -10 -13 0 10 11 9 16 -5
27 -9 8 -11 12 -5 8 7 -3 21 -1 30 4 10 6 13 11 6 11 -6 0 -10 9 -8 20 2 11
-1 20 -7 20 -7 0 -3 7 8 15 10 8 14 15 7 15 -7 0 -10 7 -7 15 7 17 1 17 -66 4z"
      />
      <path d="M399 979 c-7 -4 -14 -26 -14 -48 -1 -37 1 -39 20 -29 11 6 17 7 13 3
-4 -4 0 -14 10 -22 12 -9 13 -12 2 -8 -21 6 -52 -19 -45 -37 4 -9 24 -24 46
-33 28 -13 36 -21 27 -27 -11 -6 -11 -8 0 -8 7 0 11 -4 8 -9 -3 -5 1 -12 10
-15 9 -3 12 -11 8 -18 -5 -8 -2 -9 10 -5 9 4 15 3 11 -2 -3 -5 1 -11 7 -14 7
-2 1 -4 -14 -3 -14 0 -24 6 -20 11 3 6 1 6 -3 2 -16 -14 -15 -39 1 -33 8 3 13
2 9 -3 -3 -5 -2 -11 2 -14 11 -5 -17 3 -48 16 -14 6 -35 7 -45 2 -13 -5 -15
-9 -6 -12 10 -4 10 -8 1 -19 -9 -11 -8 -14 2 -14 11 0 11 -2 -1 -10 -12 -8
-12 -10 -1 -10 10 0 11 -3 2 -14 -7 -8 -8 -16 -4 -19 11 -6 172 -1 179 5 3 3
21 2 41 -2 41 -10 66 -3 59 15 -2 7 -2 16 2 19 3 4 7 14 8 22 2 11 -3 14 -14
10 -16 -6 -16 -5 -1 7 12 9 16 27 15 67 -1 47 -5 57 -24 68 -22 13 -23 15 -7
32 14 16 15 18 0 24 -9 3 -13 11 -9 18 5 8 2 9 -10 5 -11 -5 -15 -3 -10 5 5 7
2 9 -8 6 -8 -4 -15 0 -15 7 0 10 5 11 18 4 10 -5 21 -9 26 -9 4 1 -4 8 -20 16
-15 8 -31 13 -36 10 -4 -3 -8 1 -8 9 0 8 5 11 10 8 6 -3 7 -1 3 5 -15 25 -161
57 -187 41z"
      />
      <path d="M1157 983 c-4 -3 -19 -7 -34 -8 -50 -3 -54 -5 -47 -17 4 -7 3 -8 -5
-4 -6 4 -19 2 -28 -6 -9 -7 -18 -12 -20 -10 -1 1 -9 2 -15 2 -7 0 -5 -5 5 -11
18 -10 18 -12 1 -30 -9 -10 -14 -19 -9 -19 4 0 2 -6 -4 -14 -9 -11 -8 -15 6
-20 15 -6 15 -8 3 -16 -10 -6 -3 -7 21 -4 24 4 49 0 73 -11 38 -18 63 -50 47
-59 -10 -7 -2 -56 13 -75 7 -10 7 -13 0 -8 -6 4 -10 -8 -10 -34 0 -21 3 -39 7
-39 12 0 3 -31 -12 -42 -11 -8 -10 -9 4 -4 11 3 16 2 12 -4 -3 -6 -1 -10 6
-10 6 0 1 -9 -12 -20 -17 -14 -20 -19 -9 -20 13 0 13 -1 0 -10 -11 -7 -11 -10
-2 -10 8 0 10 -5 5 -12 -14 -24 -12 -40 5 -33 9 3 13 3 9 -1 -4 -4 1 -12 10
-19 13 -9 13 -14 3 -20 -8 -5 -11 -4 -7 2 4 6 0 14 -9 17 -8 3 -12 2 -9 -3 3
-5 1 -12 -4 -16 -6 -3 -8 -12 -4 -21 4 -12 8 -12 22 -2 10 8 8 3 -4 -11 -20
-24 -20 -24 0 -17 11 4 16 4 12 0 -4 -4 1 -13 11 -21 15 -13 14 -13 -7 -3 -17
9 -26 9 -34 1 -7 -7 -8 -11 0 -11 5 0 9 -15 8 -34 l-1 -34 109 2 c97 1 110 3
113 19 1 9 -2 17 -8 17 -7 0 -7 5 1 13 11 14 5 27 -13 27 -6 0 -4 -5 4 -10 8
-5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -4 10 5 0 19 7 30 15 19 14 18
15 -6 35 -14 11 -18 18 -9 14 10 -3 20 -1 23 5 4 5 -3 13 -15 16 -19 6 -19 8
-3 20 17 12 28 65 14 65 -3 0 -14 6 -25 13 -14 11 -15 16 -5 22 8 5 11 4 7 -2
-4 -6 0 -14 9 -17 8 -3 12 -2 9 3 -3 5 0 13 5 16 7 5 5 12 -7 21 -10 7 -13 14
-8 14 6 0 3 5 -5 11 -12 8 -11 9 6 4 20 -5 21 -2 20 60 -1 37 -5 64 -11 63 -5
-1 -21 2 -34 6 -18 6 -20 10 -10 16 7 5 21 4 31 -1 35 -19 38 2 5 33 -22 20
-25 27 -10 19 30 -15 35 -14 24 8 -6 10 -7 21 -4 25 3 3 1 6 -7 6 -9 0 -10 3
-1 13 11 14 5 27 -13 27 -6 0 -4 -5 4 -10 8 -5 10 -10 4 -10 -20 0 -48 26 -35
34 6 4 8 12 5 18 -4 7 -2 8 4 4 8 -5 10 -2 6 9 -4 9 -19 22 -35 31 -32 16 -38
26 -11 18 9 -3 5 2 -9 10 -15 8 -42 17 -61 19 -19 2 -39 7 -45 10 -6 4 -13 4
-16 0z"
      />
      <path d="M3508 979 c-7 -4 -15 -6 -19 -4 -3 3 -23 -4 -44 -14 -30 -14 -34 -19
-20 -25 17 -6 17 -7 0 -26 -11 -13 -14 -20 -6 -20 7 0 9 -5 4 -12 -4 -7 -9
-21 -10 -32 -3 -14 1 -16 14 -11 48 21 71 17 107 -17 20 -19 33 -38 29 -42
-11 -11 -11 -66 0 -66 5 0 3 -8 -4 -17 -12 -15 -12 -16 1 -8 12 6 13 5 3 -5
-10 -11 -9 -16 4 -25 13 -10 13 -14 3 -20 -9 -6 -11 -4 -5 6 5 8 4 11 -1 6
-14 -13 -11 -81 5 -93 11 -9 12 -12 1 -19 -9 -6 -11 -4 -5 6 5 8 4 10 0 6 -5
-4 -10 -20 -11 -35 -7 -85 -19 -103 -66 -100 -14 1 -34 -4 -44 -12 -10 -8 -15
-10 -11 -4 4 7 1 14 -6 17 -8 3 -13 -4 -13 -19 0 -17 4 -23 14 -19 13 5 13 3
1 -11 -16 -20 -21 -44 -8 -44 6 0 8 -4 5 -9 -13 -20 80 -43 150 -38 86 7 94 9
94 18 0 4 9 10 20 14 11 3 19 12 17 18 -1 7 4 11 11 9 8 -1 11 2 8 7 -3 5 1
14 9 21 20 16 19 47 -1 58 -15 8 -15 10 0 15 12 5 15 3 10 -6 -4 -7 -3 -9 2
-4 5 5 10 13 10 18 1 5 2 10 4 12 6 8 7 37 1 37 -4 0 -7 10 -5 23 1 12 3 22 4
22 1 0 3 8 5 18 3 14 -1 17 -13 12 -13 -5 -12 -1 4 16 12 14 13 19 4 14 -12
-6 -13 -5 -3 5 10 11 9 16 -5 27 -13 10 -13 13 -2 11 11 -2 15 8 15 37 1 22
-2 48 -7 57 -5 10 -4 19 2 23 5 3 10 11 10 16 0 6 -5 7 -12 3 -7 -5 -8 -3 -2
7 11 19 14 39 5 39 -4 0 -8 6 -9 13 -2 32 -13 63 -27 73 -8 6 -12 13 -9 17 3
3 -4 8 -17 12 -16 5 -18 9 -9 15 9 6 -1 13 -31 21 -50 15 -133 19 -151 8z"
      />
      <path d="M3827 984 c-2 -2 -17 -4 -35 -6 -26 -2 -29 -4 -19 -18 7 -8 19 -21
27 -28 13 -11 13 -11 -1 -6 -13 4 -15 0 -12 -20 3 -14 10 -26 15 -26 5 0 7 -4
4 -9 -3 -4 6 -13 20 -20 24 -11 25 -31 1 -31 -8 0 -8 3 1 9 11 6 10 10 -4 15
-9 3 -19 4 -22 1 -3 -3 0 -5 7 -5 7 0 9 -5 6 -10 -3 -6 0 -13 9 -16 8 -3 12
-12 10 -19 -3 -7 0 -16 5 -20 6 -3 9 -11 6 -16 -4 -5 -2 -9 4 -9 5 0 8 -9 6
-20 -2 -11 1 -20 6 -20 6 0 7 -5 4 -10 -3 -6 1 -13 10 -16 13 -5 13 -9 3 -22
-11 -13 -11 -14 2 -7 13 7 13 6 2 -7 -11 -14 -10 -17 9 -23 17 -6 19 -9 8 -16
-11 -7 -11 -9 -1 -9 7 0 11 -4 8 -9 -4 -5 -1 -11 6 -13 6 -2 12 -12 14 -21 1
-9 -2 -15 -7 -12 -5 4 -6 -1 -3 -10 4 -9 20 -23 36 -31 16 -8 26 -19 23 -24
-4 -6 -8 -10 -11 -10 -3 0 -3 4 0 9 3 5 0 11 -7 14 -15 5 -21 -29 -7 -38 5 -3
7 -12 4 -20 -4 -8 1 -18 10 -21 12 -5 14 -10 6 -19 -8 -9 -7 -14 1 -17 11 -4
18 -23 21 -55 1 -7 6 -13 11 -13 5 0 4 -7 -3 -15 -10 -12 -10 -15 0 -15 7 0 3
-9 -9 -19 -13 -11 -20 -24 -17 -30 7 -11 -18 -21 -71 -27 -51 -6 -63 -19 -53
-54 5 -18 4 -32 -3 -39 -8 -8 -7 -11 2 -11 10 0 11 -3 3 -13 -45 -53 133 -59
209 -6 14 10 28 16 31 14 3 -3 13 4 22 14 17 19 17 20 -11 35 -15 8 -24 15
-20 16 5 0 16 -4 25 -9 12 -7 24 -3 42 12 19 16 21 19 6 13 -19 -7 -19 -6 -1
14 11 12 13 20 6 20 -7 0 -9 5 -4 13 13 20 10 31 -12 45 -15 10 -16 12 -2 8
22 -7 23 17 1 38 -8 9 -10 16 -4 16 8 0 8 6 0 20 -6 11 -8 24 -6 29 3 5 -1 12
-10 15 -9 4 -14 9 -11 13 3 3 0 17 -7 32 -7 14 -12 34 -10 44 2 14 -2 17 -14
12 -14 -5 -13 -3 2 14 18 20 7 39 -16 25 -7 -5 -8 -3 -2 7 12 20 14 39 4 39
-5 0 -7 9 -5 21 3 16 0 19 -13 14 -9 -3 -13 -3 -9 1 4 4 -1 13 -11 21 -15 13
-15 13 4 4 16 -8 21 -7 21 5 0 7 -3 14 -7 14 -4 0 -7 6 -8 13 -1 14 -10 52
-12 52 -1 0 -3 11 -6 24 -2 14 -9 30 -14 36 -5 6 -8 21 -5 33 2 12 0 22 -5 22
-5 0 -7 9 -5 20 2 11 -1 20 -6 20 -6 0 -8 4 -5 9 3 5 -1 12 -10 15 -9 3 -12
11 -8 18 5 7 2 9 -8 5 -16 -6 -19 8 -6 28 4 6 3 17 -2 25 -6 10 -14 11 -32 4
-13 -6 -37 -7 -55 -4 -18 4 -34 6 -36 4z"
      />
      <path d="M4217 978 c2 -5 1 -14 -4 -20 -12 -20 -12 -38 -1 -38 6 0 5 -6 -2
-15 -7 -8 -9 -15 -5 -15 4 0 1 -7 -6 -17 -8 -9 -14 -23 -14 -32 0 -9 -4 -22
-8 -28 -5 -7 -5 -13 -1 -13 3 0 1 -7 -6 -15 -10 -12 -10 -15 0 -15 9 0 9 -4
-1 -17 -12 -15 -12 -16 1 -8 8 4 5 -3 -7 -16 -12 -13 -22 -27 -22 -31 -1 -5
-1 -9 -2 -10 -7 -21 -3 -46 5 -41 6 4 5 -1 -3 -10 -7 -9 -10 -17 -6 -17 4 0 1
-8 -6 -17 -12 -15 -12 -16 5 -10 15 6 17 5 7 -7 -9 -11 -8 -15 4 -20 10 -4 13
-11 8 -18 -11 -19 -13 -38 -3 -38 5 0 7 -9 5 -20 -2 -11 1 -20 6 -20 6 0 7 -4
4 -10 -3 -6 -2 -10 2 -10 5 0 9 -10 10 -22 1 -13 4 -34 8 -48 11 -45 13 -55
14 -70 1 -13 3 -13 10 0 6 8 8 17 5 21 -2 4 0 10 6 14 6 4 7 11 4 17 -4 6 0
14 7 19 12 7 12 9 0 9 -11 0 -10 5 4 20 25 28 29 53 7 46 -13 -5 -13 -3 2 9
11 8 16 18 13 22 -4 3 1 15 10 26 10 10 17 21 16 23 -2 2 -3 5 -2 7 0 1 3 13
5 26 2 14 12 33 21 43 11 13 12 18 3 18 -10 0 -9 4 1 17 12 15 12 16 -1 8 -11
-6 -11 -4 2 11 12 14 14 24 7 36 -8 14 -7 16 7 11 12 -4 15 -3 10 5 -14 23
-15 51 -1 46 22 -9 30 18 9 34 -18 14 -18 14 3 8 12 -4 24 -2 28 4 3 5 1 10
-6 10 -8 0 -7 6 4 18 8 9 16 20 16 25 1 4 1 8 2 10 1 1 2 14 3 28 0 14 7 33
15 42 10 13 10 17 1 17 -9 0 -9 4 -2 13 8 9 -11 12 -93 12 -57 0 -101 -3 -98
-7z"
      />
      <path d="M3169 978 c-9 -34 -7 -48 3 -42 10 5 10 4 1 -6 -10 -11 -9 -16 5 -27
13 -10 13 -12 2 -8 -11 4 -16 -1 -16 -15 0 -11 3 -20 6 -20 4 0 15 -7 26 -16
18 -14 18 -15 -4 -4 -28 14 -39 7 -19 -11 12 -12 12 -15 -2 -26 -11 -9 -12
-12 -3 -13 8 0 10 -5 5 -12 -11 -18 -12 -117 -1 -124 4 -3 5 -11 1 -17 -10
-17 -10 -76 1 -70 5 4 6 1 0 -8 -14 -22 -11 -33 11 -47 16 -11 16 -12 2 -8
-10 3 -15 2 -12 -3 3 -5 0 -13 -6 -17 -9 -5 -7 -11 5 -19 14 -11 15 -13 1 -19
-20 -7 -19 -42 1 -50 15 -6 15 -8 -1 -26 -13 -14 -14 -20 -5 -20 7 0 10 -3 7
-7 -4 -3 1 -12 11 -20 15 -11 15 -12 1 -7 -28 9 -30 -12 -3 -25 23 -10 24 -14
13 -28 -8 -9 -15 -24 -15 -35 -1 -10 -3 -20 -4 -23 -1 -3 -3 -9 -4 -14 0 -5
-4 -13 -8 -16 -4 -4 -4 -15 1 -24 9 -19 8 -32 -2 -48 -5 -7 -4 -13 2 -13 5 0
8 -8 6 -18 -3 -17 5 -19 93 -19 100 -1 124 3 101 18 -10 6 -11 9 -1 9 21 0 13
19 -15 34 -28 16 -28 16 -3 13 31 -3 34 23 5 41 -19 12 -19 12 0 10 21 -3 28
21 10 34 -6 5 -6 8 3 8 6 0 12 5 12 11 0 5 -6 7 -12 3 -10 -6 -10 -4 0 8 10
13 10 17 -3 22 -17 6 -21 26 -4 26 5 0 7 -6 3 -12 -4 -7 -3 -9 1 -5 13 11 15
37 4 37 -6 0 -7 6 -2 13 14 22 12 39 -3 33 -10 -4 -12 -2 -8 6 5 8 2 9 -10 5
-9 -4 -15 -3 -11 2 3 5 -1 12 -7 15 -7 3 -3 3 9 1 12 -3 24 -1 28 5 3 5 1 10
-6 10 -7 0 -4 7 7 15 10 8 14 15 8 15 -5 0 -19 7 -30 16 -10 8 -13 13 -6 9 21
-11 43 14 32 35 -5 9 -6 20 -2 24 3 4 7 35 7 69 0 58 -2 63 -30 80 -21 12 -23
16 -8 12 33 -9 57 50 26 62 -12 5 -12 7 -2 14 8 5 14 30 15 66 1 63 -4 86 -16
78 -9 -6 -7 21 2 31 4 3 4 12 1 20 -3 8 0 14 7 14 6 0 8 4 5 10 -3 5 -44 9
-90 8 -50 -1 -82 -5 -78 -11 3 -5 -4 -4 -16 2 -11 7 -21 10 -22 9z"
      />
      <path d="M754 952 c-19 -14 -18 -15 1 -8 17 5 16 3 -5 -16 -19 -16 -20 -20 -6
-14 16 5 17 4 6 -9 -9 -11 -9 -15 -1 -15 7 0 9 -5 5 -12 -4 -7 -3 -8 5 -4 6 4
9 11 6 16 -3 5 0 12 6 16 8 5 9 3 4 -6 -6 -10 -4 -12 10 -6 18 6 18 6 2 -12
-9 -10 -14 -22 -10 -26 4 -4 0 -4 -9 -1 -9 4 -19 1 -24 -6 -5 -8 -3 -10 7 -4
12 7 12 6 1 -7 -11 -14 -10 -17 8 -23 20 -6 20 -7 -2 -25 -13 -11 -19 -19 -14
-20 5 0 3 -6 -3 -14 -10 -11 -7 -19 11 -36 15 -14 17 -19 7 -15 -10 4 -20 2
-23 -3 -3 -6 1 -13 10 -16 9 -4 12 -11 7 -18 -11 -18 -12 -110 -1 -124 5 -7 2
-16 -6 -23 -17 -14 -10 -44 8 -34 6 3 6 0 -2 -8 -10 -11 -8 -16 10 -27 20 -11
21 -12 3 -7 -16 4 -21 0 -22 -15 -1 -13 3 -18 10 -14 7 3 3 -2 -7 -10 -11 -9
-14 -16 -7 -16 7 0 9 -5 6 -10 -4 -6 -1 -18 7 -27 7 -10 8 -14 1 -10 -7 5 -10
1 -8 -10 1 -10 -3 -24 -9 -32 -9 -12 -8 -13 7 -8 11 4 16 3 12 -3 -3 -6 -1
-10 6 -10 6 0 1 -9 -12 -19 l-24 -19 60 3 c33 2 65 4 70 5 6 0 33 -1 60 -4 28
-2 44 -2 37 1 -6 3 -9 10 -5 16 14 22 12 39 -3 33 -9 -3 -12 -1 -9 4 3 6 2 10
-2 10 -5 0 -18 7 -29 16 -18 14 -18 15 4 4 17 -8 27 -7 44 5 13 8 17 15 10 15
-7 0 -10 4 -7 10 3 6 2 10 -4 10 -16 0 -22 -18 -9 -23 7 -3 8 -6 2 -6 -5 -1
-19 6 -30 14 -13 10 -14 13 -3 10 9 -3 22 2 30 11 8 10 9 15 3 11 -7 -4 -14
-1 -17 7 -3 8 1 13 11 12 12 -1 16 10 17 52 1 49 -6 64 -20 42 -3 -6 -1 -10 6
-10 7 0 10 -3 7 -6 -3 -3 -28 6 -55 19 -39 21 -43 25 -21 24 14 -1 24 -6 20
-11 -3 -5 2 -6 12 -3 11 5 15 3 10 -4 -4 -7 -1 -10 7 -7 8 3 14 10 14 15 0 6
4 15 9 20 5 5 6 3 2 -4 -5 -9 -2 -11 8 -7 20 8 20 9 0 28 -8 9 -11 16 -5 16 6
0 11 5 11 11 0 5 -5 7 -12 3 -7 -5 -8 -3 -2 7 15 24 14 41 -3 35 -14 -6 -14
-4 -2 10 16 20 22 77 7 68 -7 -5 -8 -2 -2 7 12 20 13 89 1 89 -6 0 -2 7 9 16
15 12 15 14 2 11 -19 -7 -19 13 1 37 12 14 11 16 -4 10 -14 -6 -16 -4 -9 7 11
19 13 59 2 59 -4 0 -7 5 -5 12 1 7 -14 12 -40 13 -23 0 -40 -2 -38 -6 3 -3 -7
-5 -20 -3 -14 2 -36 6 -49 7 -12 2 -32 -3 -42 -11z"
      />
      <path d="M1961 959 c9 -6 10 -9 2 -9 -19 0 -8 -69 13 -86 18 -13 18 -14 1 -8
-10 4 -17 3 -16 -2 1 -5 0 -11 -1 -14 -9 -25 -7 -41 6 -36 10 4 12 2 8 -6 -5
-8 -1 -9 12 -4 10 4 15 4 11 0 -4 -4 1 -13 11 -21 15 -13 14 -13 -7 -3 -19 10
-26 9 -40 -5 -9 -10 -11 -15 -4 -11 6 3 28 -1 48 -11 28 -13 32 -19 20 -26 -8
-4 -13 -13 -9 -19 4 -7 0 -7 -12 0 -11 5 -26 7 -34 5 -8 -3 -4 5 10 17 18 16
20 20 7 16 -10 -3 -15 -2 -12 4 3 6 -1 7 -10 3 -12 -4 -13 -8 -5 -13 8 -5 9
-10 2 -14 -7 -4 -7 -17 -2 -35 5 -19 4 -33 -3 -40 -8 -8 -7 -11 4 -11 12 0 12
-2 -1 -10 -11 -7 -11 -10 -2 -10 8 0 10 -5 5 -12 -11 -18 -13 -38 -3 -38 5 0
7 -8 5 -17 -2 -10 -4 -18 -5 -18 -1 0 -3 -13 -4 -28 -2 -22 1 -27 13 -22 14 4
14 4 1 -7 -19 -16 -23 -101 -5 -94 8 3 12 -2 10 -12 -1 -9 2 -20 7 -23 5 -3 2
-9 -7 -12 -11 -5 -14 -3 -9 6 4 6 3 8 -1 4 -16 -14 -15 -39 1 -33 9 3 12 1 9
-4 -3 -6 0 -10 7 -11 7 0 3 -5 -10 -11 -12 -6 -21 -16 -19 -22 5 -15 79 -17
96 -2 11 8 13 8 8 0 -5 -8 8 -12 43 -11 27 0 47 4 44 9 -3 5 -12 7 -18 5 -7
-3 -13 0 -13 7 0 6 8 12 18 12 10 0 16 -3 13 -7 -5 -8 27 -22 34 -14 3 3 0 5
-8 5 -10 0 -10 3 0 15 8 9 8 15 2 15 -11 0 -7 44 5 63 4 6 -1 8 -13 4 -18 -7
-18 -7 0 8 11 8 14 15 7 15 -7 0 -9 5 -4 13 4 6 8 22 9 35 1 17 -3 21 -14 17
-13 -5 -12 -2 2 14 12 13 14 21 6 26 -6 4 -9 14 -6 22 4 9 2 12 -7 7 -7 -5 -6
-1 3 10 17 22 22 46 9 46 -6 0 -7 6 -2 13 11 17 13 37 3 37 -5 0 -7 8 -5 18 2
9 4 17 5 17 1 0 3 8 5 18 2 13 -1 17 -12 13 -14 -6 -14 -4 -2 10 18 21 20 44
4 38 -7 -3 -16 -1 -20 5 -3 6 0 14 9 17 9 4 13 16 12 37 -2 18 1 37 6 43 5 8
3 14 -8 18 -13 5 -14 9 -4 21 13 15 1 21 -16 8 -5 -5 -2 -1 8 8 9 9 15 25 14
35 -2 10 1 24 5 30 11 13 -151 11 -172 -2 -7 -4 -10 -3 -7 2 4 5 -5 10 -19 11
-19 1 -22 -1 -12 -8z"
      />
      <path d="M2308 959 c-7 -4 -16 -5 -20 -3 -4 3 -18 -2 -30 -12 -20 -15 -21 -16
-3 -10 19 6 19 6 1 -9 -10 -8 -24 -12 -30 -8 -6 3 -8 3 -4 -2 4 -4 3 -14 -3
-21 -8 -9 -5 -11 10 -7 12 3 21 1 21 -6 0 -6 -3 -10 -7 -10 -24 4 -29 -6 -29
-60 0 -42 3 -55 11 -46 21 27 62 41 112 41 28 -1 49 1 47 3 -2 2 2 11 9 19 9
13 9 14 0 8 -7 -4 -13 -4 -14 1 0 4 -2 16 -4 25 -2 10 1 16 7 15 6 -1 12 6 13
16 2 9 0 15 -5 12 -10 -6 -21 21 -12 29 3 4 7 13 8 22 2 11 -6 14 -32 12 -19
-1 -40 -5 -46 -9z"
      />
      <path d="M2477 955 c-16 -11 -16 -13 -1 -19 22 -8 40 4 23 15 -8 5 -9 9 -4 9
31 0 32 -17 3 -34 -21 -13 -28 -15 -23 -5 5 8 4 10 0 6 -20 -18 -11 -36 27
-53 21 -9 41 -22 45 -27 3 -5 -9 -1 -26 8 -31 15 -57 13 -46 -5 3 -5 -3 -13
-12 -19 -10 -6 -13 -11 -6 -11 6 0 10 -9 8 -20 -2 -11 1 -20 6 -20 6 0 7 -6 2
-12 -9 -16 -12 -58 -3 -58 3 0 15 -7 26 -15 14 -11 15 -14 3 -9 -17 5 -32 -9
-35 -33 -1 -7 1 -13 5 -13 4 0 16 -7 27 -16 10 -8 13 -13 6 -9 -21 11 -43 -14
-32 -35 7 -13 6 -20 -3 -23 -9 -4 -8 -6 2 -6 11 -1 11 -4 1 -16 -11 -13 -10
-14 3 -10 25 10 29 -12 5 -29 -12 -9 -16 -16 -9 -16 11 0 11 -3 1 -15 -11 -13
-10 -14 1 -10 8 3 23 -1 34 -10 14 -11 15 -13 4 -9 -17 5 -32 -9 -35 -34 -2
-8 3 -11 11 -8 13 5 15 -10 2 -30 -4 -7 1 -15 10 -18 14 -6 15 -9 4 -15 -10
-7 -9 -10 8 -16 12 -3 19 -10 17 -14 -8 -12 93 -62 131 -65 21 -1 54 1 73 6
19 5 46 10 59 12 24 4 43 24 35 37 -2 4 0 10 5 14 6 3 7 15 2 28 -5 12 -7 30
-6 40 3 18 -10 23 -20 8 -7 -13 -34 -7 -85 19 -33 17 -40 24 -29 31 8 5 9 9 3
9 -5 0 -19 6 -30 14 -19 14 -19 14 1 13 14 -1 20 5 21 20 1 12 -2 20 -7 17 -5
-2 -25 5 -46 16 -21 12 -29 18 -18 14 33 -11 50 -10 48 3 -2 7 3 10 11 7 8 -3
17 -1 21 5 3 6 1 11 -6 11 -7 0 -9 3 -6 7 4 3 -1 12 -11 20 -16 12 -16 13 2 7
16 -6 17 -4 8 13 -6 11 -8 23 -5 27 10 10 12 37 2 31 -4 -3 -30 6 -56 20 -26
14 -40 24 -29 20 10 -3 26 0 35 7 14 12 14 11 2 -4 -16 -22 1 -24 19 -3 7 8
17 15 23 15 6 0 2 -7 -9 -15 -12 -9 -14 -14 -6 -15 7 0 10 -4 7 -10 -3 -6 1
-7 10 -4 9 4 14 13 11 20 -3 8 0 14 7 14 8 0 8 3 2 8 -6 4 -10 18 -10 32 0 14
-6 31 -15 38 -8 8 -10 11 -3 8 6 -4 14 -2 17 3 4 5 -10 15 -29 21 -19 6 -35
16 -35 21 0 6 8 5 20 -1 22 -12 44 -5 35 11 -3 5 1 6 10 3 11 -5 14 -2 9 10
-3 9 -11 16 -18 16 -6 0 -4 5 4 10 12 8 12 10 1 10 -9 0 -10 4 -4 13 5 6 9 21
9 32 -1 18 -8 20 -96 21 -64 1 -102 -2 -113 -11z"
      />
      <path d="M2873 958 c-2 -5 1 -8 7 -8 6 0 8 -5 4 -12 -4 -7 -3 -8 4 -4 15 9 74
-22 67 -34 -4 -6 -8 -10 -11 -10 -3 0 -2 5 2 12 5 8 2 9 -10 5 -9 -4 -15 -3
-12 2 3 5 -4 9 -17 9 -18 0 -22 -6 -22 -30 -1 -21 3 -27 14 -23 10 4 9 -1 -5
-16 -12 -15 -14 -20 -4 -15 8 4 6 0 -4 -8 -13 -10 -15 -16 -7 -16 11 0 11 -3
1 -15 -15 -18 0 -20 20 -2 12 10 12 9 1 -5 -7 -10 -10 -18 -6 -18 4 0 2 -7 -5
-15 -7 -8 -9 -15 -5 -15 4 0 2 -7 -5 -15 -11 -13 -10 -14 3 -10 8 4 18 1 23
-6 4 -7 3 -10 -2 -6 -6 3 -16 2 -24 -4 -11 -6 -12 -9 -2 -9 8 0 10 -5 6 -12
-5 -7 -2 -9 9 -5 12 4 15 1 12 -14 -3 -15 0 -19 11 -15 9 3 12 2 9 -5 -4 -5
-15 -7 -25 -4 -13 4 -19 -1 -22 -19 -3 -14 -1 -31 3 -38 8 -12 13 -4 10 14 0
4 6 5 14 2 12 -5 11 -9 -7 -23 -21 -18 -32 -71 -15 -71 5 0 3 -8 -4 -17 -12
-14 -12 -16 2 -11 9 4 16 1 16 -8 0 -7 -5 -10 -11 -7 -6 3 -8 3 -4 -2 4 -4 3
-14 -3 -21 -7 -8 -7 -14 1 -19 8 -5 8 -11 -1 -21 -9 -11 -9 -14 1 -14 9 0 10
-3 1 -14 -9 -10 -8 -15 3 -19 17 -7 15 -28 -4 -53 -14 -16 -13 -17 5 -10 16 6
18 5 8 -5 -7 -7 -13 -16 -13 -19 0 -10 208 -6 217 4 5 6 2 6 -7 1 -13 -8 -13
-7 -1 8 9 12 10 17 2 17 -7 0 -9 5 -4 13 14 22 12 39 -3 33 -10 -4 -12 -2 -8
6 5 7 2 9 -8 6 -8 -4 -15 -1 -15 7 0 7 6 11 13 8 12 -5 27 12 30 35 2 9 -4 11
-14 8 -13 -5 -12 -2 2 9 14 10 15 15 6 15 -11 0 -11 3 -2 12 9 9 7 14 -6 25
-12 9 -13 12 -2 8 11 -4 15 3 15 25 1 16 -3 30 -8 30 -4 0 -5 6 0 13 11 17 12
57 1 57 -5 0 -6 6 -1 13 14 23 12 39 -5 33 -13 -5 -12 -2 2 9 12 9 15 15 7 15
-11 0 -11 3 0 17 12 15 12 16 -1 8 -13 -8 -13 -7 -1 8 11 14 11 17 0 17 -11 0
-11 2 1 10 11 7 11 10 2 10 -8 0 -10 5 -5 13 11 17 13 37 3 37 -5 0 -7 8 -5
18 2 9 4 17 5 17 1 0 3 8 5 18 2 9 -1 17 -7 17 -6 0 -5 6 2 15 11 13 10 15 -6
9 -18 -5 -18 -5 0 10 11 9 14 16 7 16 -7 0 -9 5 -6 10 3 6 0 10 -7 10 -8 0 -6
5 5 11 9 6 15 14 12 19 -7 11 -215 8 -222 -2z"
      />
      <path d="M519 501 c-1 -3 -1 -10 0 -14 1 -4 -8 -3 -20 3 -13 8 -25 8 -31 2 -6
-6 2 -14 22 -22 17 -7 33 -17 36 -22 4 -6 -3 -5 -15 1 -12 7 -27 9 -33 5 -7
-5 -8 -3 -3 6 5 8 3 11 -6 8 -8 -3 -14 -12 -14 -20 0 -9 -8 -25 -18 -35 -9
-11 -15 -22 -12 -25 3 -3 -9 -4 -27 -2 -18 1 -27 0 -20 -3 6 -2 10 -9 6 -14
-3 -5 1 -9 8 -9 9 0 9 -3 -2 -10 -11 -7 -11 -10 -2 -10 8 0 10 -5 5 -12 -13
-21 -12 -37 2 -32 17 7 14 -18 -4 -40 -7 -9 -10 -17 -5 -17 32 -4 41 -3 59 6
11 5 27 11 35 12 63 11 80 16 80 25 0 5 -6 6 -12 2 -7 -4 -4 2 6 14 12 14 26
20 38 16 17 -5 26 10 11 19 -5 2 -3 3 3 1 18 -4 56 27 50 41 -2 7 0 17 6 23 8
8 7 13 -5 18 -9 3 -14 10 -11 15 3 5 0 9 -7 9 -7 0 -10 3 -7 6 4 3 15 1 25 -5
15 -8 20 -7 20 5 0 7 -5 14 -11 14 -6 0 -4 5 4 10 28 17 -5 32 -79 34 -39 1
-72 0 -72 -3z"
      />
    </g>
  </svg>

);

export default Entrupy;
