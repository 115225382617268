import { combineReducers } from '@reduxjs/toolkit';

import { reducer as discountsreducer } from '../slices/discounts';
import { reducer as importqueuereducer } from '../slices/importqueue';
import { reducer as menuReducer } from '../slices/itemsmenu';
import { reducer as settingsreducer } from '../slices/settings';
import { reducer as shopifylistreducer } from '../slices/shopifylist';

const rootReducer = combineReducers({
  itemsmenu: menuReducer,
  shopifylist: shopifylistreducer,
  importqueue: importqueuereducer,
  discounts: discountsreducer,
  settings: settingsreducer
});

export default rootReducer;
